.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Adjust font size for smaller screens (e.g., mobile) */
@media (min-width: 300px) {
  .title{
    font-size: 25px;
    position: relative; 
    bottom: -70%;
    text-align: center;
  }
  .iconTitle {
    font-size: 12px;
  }
  .iconText {
    font-size: 10px;
  }
  .icon {
   font-size:20px;
  }
}

@media (min-width: 500px) {
  .title{
    font-size: 30px;
    position: relative; 
    bottom: -70%;
    text-align: center;
  }
  .iconTitle {
    font-size: 19px;
  }
  .iconText {
    font-size: 15px;
  }
  .icon {
    font-size:25px;
   }
}

/* Adjust font size for larger screens (e.g., desktop) */
@media (min-width: 900px) {
  .title {
    font-size: 45px;
    position: relative;
     bottom: -70%;
     text-align: center;
  }
  .iconTitle {
    font-size: 25px;
  }
  .iconText {
    font-size: 18px;
  }
  .icon {
    font-size:30px;
   }
}

